.sidebar-container {
  display: flex;
  flex-direction: row;
}

.sidebar {
  background-color: var(--sidebar-bg);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 55px;
  height: 100%;
}
.sb-top {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sb-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sb-icon {
  height: 25px;
  width: 25px;
  padding: 15px;
  display: inline-block;
  color: var(--sidebar-icon);
}
.sb-icon:hover {
  cursor: pointer;
  color: var(--sidebar-icon-hover);
}

@media (max-width: 750px) {
  .sidebar-container {
    display: flex;
    flex-direction: column;
  }
  .sidebar {
    flex-direction: row;
    width: 100vw;
    overflow: auto;
  }
  .sb-top {
    flex-direction: row;
  }
  .sb-bottom {
    flex-direction: row;
  }
  .sb-icon {
    padding: 10px 15px;
  }
}
