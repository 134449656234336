.projects-container {
	display: flex;
	flex-direction: column;
	color: var(--file-text);
	margin: 0px 15px;
}
.pjs-project {
	width: 100%;
	margin: 1% 0;
	border-radius: 8px;
}
.pjs-project * {
	margin: 15px 0px;
}
.pjs-project h2 {
	text-transform: uppercase;
}
.projects-container hr {
	border: 1px solid rgba(255, 255, 255, 0.075);
}
.pjs-project img {
	width: 100%;
	border-radius: 8px;
}
.pj-links a {
	color: #39a2db;
	margin-right: 30px;
}
