.layout {
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-direction: column;
  width: 100vw;
}
.top-container {
  height: 40px;
  width: 100%;
}
.main {
  display: flex;
  height: calc(100vh - 40px);
}
.left-container {
  display: flex;
  flex-direction: row;
}
.main-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
}

@media (max-width: 750px) {
  .main {
    flex-direction: column;
  }
}
