/* my VSCODE colors */
:root {
	--sidebar-bg: #333842;
	--sidebar-icon: #757982;
	--sidebar-icon-hover: #d7dae0;
	--header-bg: #21252b;
	--header-tc: #9da5b4;
	--header-tc-hover: #383b41;
	--header-icon: #9da5b4;
	--header-icon-close: white;
	--header-icon-close-hover: #d41324;
	--explorer-bg: #21252b;
	--explorer-title: #cccccc;
	--explorer-item-bg: #333842;
	--explorer-file-hover: #262a31;
	--explorer-file-active: #2c313a;
	--taskbar-bg: #21252b;
	--taskbar-file-bg: #21252b;
	--taskbar-file-border: #292c34;
	--taskbar-file-gap: #181a1f;
	--taskbar-file-text: #7a838a;
	--taskbar-file-active: #282c34;
	--taskbar-file-active-text: #d7dae0;
	--file-bg: #282c34;
	--file-bg-hover: #2c313a;
	--file-bg-hover-border: #2c313a;
	--file-text: #abb2bf;
	--caret-color: #618cff;
	--css-name: #d18d4c;
	--css-bracket: #f1d700;
	/* --css-bracket: #e06c75; */
	--css-value: #46b6c2;
	--css-numerical: #cb9a67;
	--css-type: #abb2bf;
	--css-quote: #98c379;
	--cb-num: #636d83;
	--cb-num-hover: #acb2bf;

	--sk-keyword: #bd78d2;
	--sk-import-name: #e06a71;
	--sk-location: #98be63;
	--sk-variable: #bd78d2;
	--sk-function: #44a3ef;
	--sk-equals: #56b6c2;
	--sk-arrow: #bd78d2;
	--sk-create: #f1d700;
	/* --sk-bracket: #e06c75; */
	--sk-bracket: #f1d700;
	--sk-return: #bd78d2;
	--sk-element: #e06a71;
	--sk-element-bracket: #abb2bf;

	--comment-color: #5d6370;
}

/* Dark+ */
[data-theme="darkplus"] {
	--sidebar-bg: #333333;
	--sidebar-icon: #858585;
	--sidebar-icon-hover: #ffffff;
	--header-bg: #3c3c3c;
	--header-tc: #bfbfbf;
	--header-tc-hover: #474748;
	--header-icon: #cccccc;
	--header-icon-close: #fefcfc;
	--header-icon-close-hover: #d61425;
	--explorer-bg: #252526;
	--explorer-title: #cccccc;
	--explorer-item-bg: #252526;
	--explorer-file-hover: #2a2d2e;
	--explorer-file-active: #37373d;
	--taskbar-bg: #252526;
	--taskbar-file-bg: #2d2d2d;
	--taskbar-file-border: #1e1e1e;
	--taskbar-file-gap: #252526;
	--taskbar-file-text: #969690;
	--taskbar-file-active: #1e1e1e;
	--taskbar-file-active-text: #ffffff;
	--file-bg: #1e1e1e;
	--file-bg-hover: #1e1e1e;
	--file-bg-hover-border: #282828;
	--file-text: #d4d4d4;
	--caret-color: #aeafad;
	--css-name: #d7ba7d;
	--css-value: #ce723b;
	--css-numerical: #aace9c;
	--css-type: #9cdcda;
	--css-quote: #94cea8;
	--cb-num: #858585;
	--cb-num-hover: #c6c6c6;

	--sk-keyword: #bb86c0;
	--sk-import-name: #9cdcfe;
	--sk-location: #ce9178;
	--sk-variable: #569cd6;
	--sk-function: #dcdc9d;
	--sk-equals: #d4d4d4;
	--sk-bracket: #ffd700;
	--sk-arrow: #569cd6;
	--sk-create: #ffd700;
	--sk-return: #bb86b6;
	--sk-element: #358cd6;
	--sk-element-bracket: #7a7e80;

	--comment-color: #709954;
}

[data-theme="dracula"] {
	--sidebar-bg: #343746;
	--sidebar-icon: #6272a4;
	--sidebar-icon-hover: #f8f8f2;
	--header-bg: #21222c;
	--header-tc: #f8f8f2;
	--header-tc-hover: #383942;
	--header-icon: #f8f8f2;
	--header-icon-close: #f8f8f2;
	--header-icon-close-hover: #d41224;
	--explorer-bg: #21222c;
	--explorer-title: #f8f8f2;
	--explorer-item-bg: #21222c;
	--explorer-file-hover: #313341;
	--explorer-file-active: #44475a;
	--taskbar-bg: #191a21;
	--taskbar-file-bg: #21222c;
	--taskbar-file-border: #8f527f;
	--taskbar-file-gap: #191a21;
	--taskbar-file-text: #6272a4;
	--taskbar-file-active: #282a36;
	--taskbar-file-active-text: #f8f8f2;
	--file-bg: #282a36;
	--file-bg-hover: #282a36;
	--file-bg-hover-border: #45475a;
	--file-text: #f8f8ec;
	--caret-color: #aeafad;
	--css-name: #50fa7b;
	--css-bracket: #ffca1f;
	--css-value: #af93f9;
	--css-type: #8be9f0;
	--css-quote: #ddf284;
	--cb-num: #6072a4;
	--cb-num-hover: #c6c6c6;

	--sk-keyword: #ff79aa;
	--sk-import-name: #f8f8f2;
	--sk-location: #f1f081;
	--sk-semicolon: #f8f8f2;
	--sk-variable: #ff79aa;
	--sk-function: #39ed7b;
	--sk-equals: #ff79c6;
	--sk-arrow: #ff79c6;
	--sk-create: #8be9fd;
	--sk-bracket: #ffca1f;
	--sk-return: #ff79aa;
	--sk-element: #ff79aa;
	--sk-element-bracket: #ebf8f2;

	--comment-color: #6572a4;
}
