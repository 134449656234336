.explorer {
  min-width: 14vw;
  height: 100%;
  background: var(--explorer-bg);
  color: var(--explorer-title);
}
.exp-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.exp-title {
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  color: var(--explorer-title);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 10px;
}
.exp-dots {
  width: 15px;
  height: 15px;
  color: var(--explorer-title);
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}
.exp-dots:hover {
  background: var(--header-tc-hover);
}

.exp-arrow {
  color: var(--explorer-title);
  width: 25px;
  height: 25px;
}
.exp-file-type {
  width: 15px;
  height: 15px;
  padding: 5px 5px 5px 25px;
}

.exp-file-close {
  width: 10px;
  height: 10px;
}

.exp-item-container {
  display: flex;
  align-items: center;
  background: var(--explorer-item-bg);
  font-family: "Open Sans", sans-serif;
  cursor: pointer;
}
.exp-item-content {
  font-family: "Open Sans", sans-serif;
}
.exp-list-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}
.exp-list-item-active {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  background: var(--explorer-file-active);
}

.exp-list-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  width: 100%;
}
.exp-list-item:hover {
  background: var(--explorer-file-hover);
}
