.skills-container {
  display: flex;
  cursor: text;
}
.skills {
  font-family: "Fira Mono", monospace;
  padding: 1.25rem 0.5rem;
  font-size: 1.25rem;
  color: var(--file-text);
}
.sk-keyword {
  color: var(--sk-keyword);
}
.sk-import-name {
  color: var(--sk-import-name);
}
.sk-location {
  color: var(--sk-location);
}
.sk-semicolon {
  color: var(--file-text);
}
.sk-variable {
  color: var(--sk-variable);
}
.sk-function {
  color: var(--sk-function);
}
.sk-equals {
  color: var(--sk-equals);
}
.sk-bracket {
  color: var(--sk-bracket);
}
.sk-arrow {
  color: var(--sk-arrow);
}
.sk-create {
  color: var(--sk-create);
}
.sk-return {
  color: var(--sk-return);
}
.sk-element {
  color: var(--sk-element);
}
.sk-element-bracket {
  color: var(--sk-element-bracket);
}
.sk-codebar {
  padding: 1.15rem 0.5rem;
  color: var(--cb-num);
}
.sk-cb-num {
  margin: 2.5px;
  font-size: 1rem;
}

@media (max-width: 750px) {
  .skills {
    font-size: 1rem;
    line-height: 1.5;
  }
}
