@import url(https://fonts.googleapis.com/css2?family=Fira+Mono&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap);
.header {
  display: flex;
  justify-content: space-between;
  height: 100%;
  background: var(--header-bg);
}
.header p {
  color: var(--header-tc);
  cursor: default;
}
.header button {
  color: var(--header-tc);
  font-family: "Open Sans", sans-serif;
  background: transparent;
  border: none;
  font-size: 16px;
}
/* .header button:hover {
  background: var(--header-tc-hover);
} */
.hdr-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1;
}
.hdr-left * {
  padding: 8px 5px;
}
.hdr-logo {
  width: 25px;
  height: 25px;
}

.hdr-middle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 2 1;
  text-align: center;
}

.hdr-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1 1;
}
.hdr-right * {
  padding: 10px;
}

.hdr-icon {
  width: 15px;
  height: 15px;
  color: var(--header-icon);
}
.hdr-icon:hover {
  background: var(--header-tc-hover);
}
.hdr-icon-close:hover {
  background: var(--header-icon-close-hover);
  color: var(--header-icon-close);
}

@media (max-width: 950px) {
  .hdr-left *:not(:first-child) {
    display: none;
  }
}
@media (max-width: 720px) {
  .hdr-left {
    display: none;
  }
  .hdr-right {
    display: none;
  }
}
@media screen and (max-width: 350px) {
  .vscode {
    display: none;
  }
}

.explorer {
  min-width: 14vw;
  height: 100%;
  background: var(--explorer-bg);
  color: var(--explorer-title);
}
.exp-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.exp-title {
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  color: var(--explorer-title);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 10px;
}
.exp-dots {
  width: 15px;
  height: 15px;
  color: var(--explorer-title);
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}
.exp-dots:hover {
  background: var(--header-tc-hover);
}

.exp-arrow {
  color: var(--explorer-title);
  width: 25px;
  height: 25px;
}
.exp-file-type {
  width: 15px;
  height: 15px;
  padding: 5px 5px 5px 25px;
}

.exp-file-close {
  width: 10px;
  height: 10px;
}

.exp-item-container {
  display: flex;
  align-items: center;
  background: var(--explorer-item-bg);
  font-family: "Open Sans", sans-serif;
  cursor: pointer;
}
.exp-item-content {
  font-family: "Open Sans", sans-serif;
}
.exp-list-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}
.exp-list-item-active {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  background: var(--explorer-file-active);
}

.exp-list-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  width: 100%;
}
.exp-list-item:hover {
  background: var(--explorer-file-hover);
}

.settings-container {
  width: 200px;
  height: 300px;
  position: absolute;
  color: white;
  background-color: var(--sidebar-bg);
  left: 55px;
  bottom: 0;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.settings-container p {
  margin: 0.25rem 0;
}
.settings-container hr {
  width: 90%;
  margin: 0.5rem 0;
}
.settings-theme-option {
  cursor: pointer;
  border: none;
  background: transparent;
  color: white;
  width: 100%;
  padding: 0.3rem 1rem;
  text-align: left;
  font-size: 0.9rem;
  font-family: "Open Sans", sans-serif;
}
.settings-theme-option:hover {
  background-color: var(--explorer-file-active);
}

@media screen and (max-width: 750px) {
  .settings-container {
    left: 100%;
    top: 90px;
    transform: translateX(-100%);
  }
}

.sidebar-container {
  display: flex;
  flex-direction: row;
}

.sidebar {
  background-color: var(--sidebar-bg);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 55px;
  height: 100%;
}
.sb-top {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sb-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sb-icon {
  height: 25px;
  width: 25px;
  padding: 15px;
  display: inline-block;
  color: var(--sidebar-icon);
}
.sb-icon:hover {
  cursor: pointer;
  color: var(--sidebar-icon-hover);
}

@media (max-width: 750px) {
  .sidebar-container {
    display: flex;
    flex-direction: column;
  }
  .sidebar {
    flex-direction: row;
    width: 100vw;
    overflow: auto;
  }
  .sb-top {
    flex-direction: row;
  }
  .sb-bottom {
    flex-direction: row;
  }
  .sb-icon {
    padding: 10px 15px;
  }
}

.about {
  color: var(--file-text);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.about * {
  padding: 1rem;
}
.ab-pic {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.about h1 {
  font-size: 2.5rem;
}
.about h3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: #39a2db;
}
.ab-underline {
  padding: 0;
  width: 200px;
}
.about p {
  line-height: 2;
  font-size: 1.5rem;
  text-align: center;
}
.about .resume {
  color: var(--file-text);
  font-size: 1.5rem;
  color: #39a2db;
}

@media (max-width: 750px) {
  .about h2 {
    font-size: 2rem;
  }
  .about h3 {
    font-size: 1rem;
  }
  .about p {
    font-size: 1.15rem;
  }
}

.skills-container {
  display: flex;
  cursor: text;
}
.skills {
  font-family: "Fira Mono", monospace;
  padding: 1.25rem 0.5rem;
  font-size: 1.25rem;
  color: var(--file-text);
}
.sk-keyword {
  color: var(--sk-keyword);
}
.sk-import-name {
  color: var(--sk-import-name);
}
.sk-location {
  color: var(--sk-location);
}
.sk-semicolon {
  color: var(--file-text);
}
.sk-variable {
  color: var(--sk-variable);
}
.sk-function {
  color: var(--sk-function);
}
.sk-equals {
  color: var(--sk-equals);
}
.sk-bracket {
  color: var(--sk-bracket);
}
.sk-arrow {
  color: var(--sk-arrow);
}
.sk-create {
  color: var(--sk-create);
}
.sk-return {
  color: var(--sk-return);
}
.sk-element {
  color: var(--sk-element);
}
.sk-element-bracket {
  color: var(--sk-element-bracket);
}
.sk-codebar {
  padding: 1.15rem 0.5rem;
  color: var(--cb-num);
}
.sk-cb-num {
  margin: 2.5px;
  font-size: 1rem;
}

@media (max-width: 750px) {
  .skills {
    font-size: 1rem;
    line-height: 1.5;
  }
}

.projects-container {
	display: flex;
	flex-direction: column;
	color: var(--file-text);
	margin: 0px 15px;
}
.pjs-project {
	width: 100%;
	margin: 1% 0;
	border-radius: 8px;
}
.pjs-project * {
	margin: 15px 0px;
}
.pjs-project h2 {
	text-transform: uppercase;
}
.projects-container hr {
	border: 1px solid rgba(255, 255, 255, 0.075);
}
.pjs-project img {
	width: 100%;
	border-radius: 8px;
}
.pj-links a {
	color: #39a2db;
	margin-right: 30px;
}

.file-container {
	background: var(--file-bg);
	width: 100%;
	height: 100%;
	/* overflow: auto; */
	overflow-y: auto;
}
.editor-instance {
	font-family: "Fira Mono", monospace;
	--editor-line-height: 1.33;
	--editor-font-size: 1rem;
	--editor-view-height: calc(
		var(--editor-line-height) * var(--editor-font-size)
	);
	counter-reset: line-numbers;
	color: var(--css-type);
	padding: calc((var(--editor-line-height) * var(--editor-font-size)) / 4) 0;
}

::-webkit-scrollbar {
	width: 12px;
}
::-webkit-scrollbar-thumb {
	background: #3b414d;
}
::-webkit-scrollbar-thumb:hover {
	background: #414855;
}
::-webkit-scrollbar-track {
	background: #282c34;
	box-shadow: inset 1px 0 0 0 #35383f, inset -1px 0 0 0 #181a1f;
}

@keyframes blink {
	0%,
	49% {
		opacity: 1;
	}
	50%,
	100% {
		opacity: 0;
	}
}

#caret {
	width: 2px;
	height: var(--editor-view-height);
	background-color: var(--caret-color); /* Safari 4+ */ /* Fx 5+ */ /* Opera 12+ */
	animation: blink 1s infinite; /* IE 10+, Fx 29+ */
}

.line,
.view-line,
.view-number {
	display: flex;
	box-sizing: border-box;
	word-break: break-all;
	position: relative;
}
.line:hover .view-number::before {
	color: var(--cb-num-hover);
}
.view-line {
	width: 100%;
}
.view-line:hover {
	background: var(--file-bg-hover);
}
.view-line:hover::after {
	content: "";
	position: absolute;
	inset: 0;
	left: 1px;
	border: 2px solid var(--file-bg-hover-border);
	border-right: none;
}
.view-number::before {
	content: counter(line-numbers);
	counter-increment: line-numbers;
	font-size: var(--editor-font-size);
	line-height: var(--editor-line-height);
	/* height: calc(16 * var(--editor-line-height)); */
	/* height: var(--editor-view-height); */
	text-align: right;
	vertical-align: middle !important;
	color: var(--cb-num);
	margin-right: 1rem;
	width: 2em;
}

.indent {
	padding-left: 1rem;
	border-left: 1px solid var(--css-bracket);
	line-height: inherit;
}
.comment {
	color: var(--comment-color);
}
.quotation {
	color: var(--css-quote);
}
.parenthesis {
	color: var(--sk-arrow);
}
.url {
	color: var(--css-value);
}
.url a {
	text-decoration-thickness: 1px;
	text-underline-offset: 4px;
}
.brace.css {
	color: var(--css-bracket);
}
.brace.js {
	color: var(--css-bracket);
}
.numerical {
	color: var(--css-numerical);
}
.color-preview input {
	-webkit-appearance: none;
	appearance: none;
	background-color: transparent;
	padding: 0;
	margin: 0;
	border: none;
	cursor: pointer;
	inline-size: var(--editor-view-height);
	block-size: var(--editor-view-height);
}
.color-preview {
	display: inline-flex;
	align-items: center;
	mix-blend-mode: hard-light;
}

@media screen and (max-width: 750px) {
	.editor-instance {
		--editor-font-size: 0.75rem;
	}
	.view-number::before {
		width: 3ch;
		margin-right: 1ch;
		/* background-color: rgba(255, 255, 255, 0.03); */
	}
}

.taskbar {
	display: flex;
	flex-direction: row;
	grid-gap: 1px;
	gap: 1px;
	background: var(--taskbar-bg);
	box-shadow: rgba(0, 0, 0, 0.7) 0px 0px 5px;
	width: 100%;
	overflow: auto;
}
.taskbar > * {
	border-left: 1px solid #181a1f;
}
.taskbar > *:nth-child(1) {
	border-left: none;
}

.tb-icon {
	width: 15px;
	height: 15px;
}
.tb-file {
	display: flex;
	align-items: center;
	background: var(--taskbar-file-bg);
	color: var(--taskbar-file-text);
	cursor: pointer;
	padding: 5px;
	border-top: 1px solid var(--transparent);
}
.tb-file * {
	padding: 5px;
}
.tb-close {
	width: 10px;
	height: 10px;
	border-radius: 5px;
}
.tb-close:hover {
	background-color: rgba(255, 255, 255, 0.08);
}
.tb-file-active {
	display: flex;
	align-items: center;
	background: var(--taskbar-file-active);
	color: var(--taskbar-file-active-text);
	cursor: pointer;
	padding: 5px;
	border-top: 1px solid var(--taskbar-file-border);
}
.tb-file-active * {
	padding: 5px;
}

.layout {
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-direction: column;
  width: 100vw;
}
.top-container {
  height: 40px;
  width: 100%;
}
.main {
  display: flex;
  height: calc(100vh - 40px);
}
.left-container {
  display: flex;
  flex-direction: row;
}
.main-container {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  width: 100%;
  height: 100%;
}

@media (max-width: 750px) {
  .main {
    flex-direction: column;
  }
}

/* my VSCODE colors */
:root {
	--sidebar-bg: #333842;
	--sidebar-icon: #757982;
	--sidebar-icon-hover: #d7dae0;
	--header-bg: #21252b;
	--header-tc: #9da5b4;
	--header-tc-hover: #383b41;
	--header-icon: #9da5b4;
	--header-icon-close: white;
	--header-icon-close-hover: #d41324;
	--explorer-bg: #21252b;
	--explorer-title: #cccccc;
	--explorer-item-bg: #333842;
	--explorer-file-hover: #262a31;
	--explorer-file-active: #2c313a;
	--taskbar-bg: #21252b;
	--taskbar-file-bg: #21252b;
	--taskbar-file-border: #292c34;
	--taskbar-file-gap: #181a1f;
	--taskbar-file-text: #7a838a;
	--taskbar-file-active: #282c34;
	--taskbar-file-active-text: #d7dae0;
	--file-bg: #282c34;
	--file-bg-hover: #2c313a;
	--file-bg-hover-border: #2c313a;
	--file-text: #abb2bf;
	--caret-color: #618cff;
	--css-name: #d18d4c;
	--css-bracket: #f1d700;
	/* --css-bracket: #e06c75; */
	--css-value: #46b6c2;
	--css-numerical: #cb9a67;
	--css-type: #abb2bf;
	--css-quote: #98c379;
	--cb-num: #636d83;
	--cb-num-hover: #acb2bf;

	--sk-keyword: #bd78d2;
	--sk-import-name: #e06a71;
	--sk-location: #98be63;
	--sk-variable: #bd78d2;
	--sk-function: #44a3ef;
	--sk-equals: #56b6c2;
	--sk-arrow: #bd78d2;
	--sk-create: #f1d700;
	/* --sk-bracket: #e06c75; */
	--sk-bracket: #f1d700;
	--sk-return: #bd78d2;
	--sk-element: #e06a71;
	--sk-element-bracket: #abb2bf;

	--comment-color: #5d6370;
}

/* Dark+ */
[data-theme="darkplus"] {
	--sidebar-bg: #333333;
	--sidebar-icon: #858585;
	--sidebar-icon-hover: #ffffff;
	--header-bg: #3c3c3c;
	--header-tc: #bfbfbf;
	--header-tc-hover: #474748;
	--header-icon: #cccccc;
	--header-icon-close: #fefcfc;
	--header-icon-close-hover: #d61425;
	--explorer-bg: #252526;
	--explorer-title: #cccccc;
	--explorer-item-bg: #252526;
	--explorer-file-hover: #2a2d2e;
	--explorer-file-active: #37373d;
	--taskbar-bg: #252526;
	--taskbar-file-bg: #2d2d2d;
	--taskbar-file-border: #1e1e1e;
	--taskbar-file-gap: #252526;
	--taskbar-file-text: #969690;
	--taskbar-file-active: #1e1e1e;
	--taskbar-file-active-text: #ffffff;
	--file-bg: #1e1e1e;
	--file-bg-hover: #1e1e1e;
	--file-bg-hover-border: #282828;
	--file-text: #d4d4d4;
	--caret-color: #aeafad;
	--css-name: #d7ba7d;
	--css-value: #ce723b;
	--css-numerical: #aace9c;
	--css-type: #9cdcda;
	--css-quote: #94cea8;
	--cb-num: #858585;
	--cb-num-hover: #c6c6c6;

	--sk-keyword: #bb86c0;
	--sk-import-name: #9cdcfe;
	--sk-location: #ce9178;
	--sk-variable: #569cd6;
	--sk-function: #dcdc9d;
	--sk-equals: #d4d4d4;
	--sk-bracket: #ffd700;
	--sk-arrow: #569cd6;
	--sk-create: #ffd700;
	--sk-return: #bb86b6;
	--sk-element: #358cd6;
	--sk-element-bracket: #7a7e80;

	--comment-color: #709954;
}

[data-theme="dracula"] {
	--sidebar-bg: #343746;
	--sidebar-icon: #6272a4;
	--sidebar-icon-hover: #f8f8f2;
	--header-bg: #21222c;
	--header-tc: #f8f8f2;
	--header-tc-hover: #383942;
	--header-icon: #f8f8f2;
	--header-icon-close: #f8f8f2;
	--header-icon-close-hover: #d41224;
	--explorer-bg: #21222c;
	--explorer-title: #f8f8f2;
	--explorer-item-bg: #21222c;
	--explorer-file-hover: #313341;
	--explorer-file-active: #44475a;
	--taskbar-bg: #191a21;
	--taskbar-file-bg: #21222c;
	--taskbar-file-border: #8f527f;
	--taskbar-file-gap: #191a21;
	--taskbar-file-text: #6272a4;
	--taskbar-file-active: #282a36;
	--taskbar-file-active-text: #f8f8f2;
	--file-bg: #282a36;
	--file-bg-hover: #282a36;
	--file-bg-hover-border: #45475a;
	--file-text: #f8f8ec;
	--caret-color: #aeafad;
	--css-name: #50fa7b;
	--css-bracket: #ffca1f;
	--css-value: #af93f9;
	--css-type: #8be9f0;
	--css-quote: #ddf284;
	--cb-num: #6072a4;
	--cb-num-hover: #c6c6c6;

	--sk-keyword: #ff79aa;
	--sk-import-name: #f8f8f2;
	--sk-location: #f1f081;
	--sk-semicolon: #f8f8f2;
	--sk-variable: #ff79aa;
	--sk-function: #39ed7b;
	--sk-equals: #ff79c6;
	--sk-arrow: #ff79c6;
	--sk-create: #8be9fd;
	--sk-bracket: #ffca1f;
	--sk-return: #ff79aa;
	--sk-element: #ff79aa;
	--sk-element-bracket: #ebf8f2;

	--comment-color: #6572a4;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
}

